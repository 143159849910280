import { WebhookResponse } from '../types';

const GOHIGHLEVEL_WEBHOOK_URL = 'https://services.leadconnectorhq.com/hooks/BldMYEQj8ZFWMPkwj4xb/webhook-trigger/7d0ffd52-5503-452c-8cd3-78894edcf1d1';

export async function submitToGoHighLevel(email: string): Promise<WebhookResponse> {
  try {
    const response = await fetch(GOHIGHLEVEL_WEBHOOK_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error('Failed to submit to GoHighLevel');
    }

    return { success: true };
  } catch (error) {
    console.error('GoHighLevel submission error:', error);
    return { success: false, error };
  }
}