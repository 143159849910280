import { type FC, useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { submitToGoHighLevel } from '../lib/webhooks/gohighlevel';

export const WaitlistForm: FC = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('sending');

    try {
      const response = await submitToGoHighLevel(email);
      
      if (!response.success) {
        throw new Error('Failed to join waitlist');
      }
      
      setStatus('success');
      setEmail('');
    } catch (err) {
      console.error('Form submission error:', err);
      setStatus('error');
    }
  };

  return (
    <div className="space-y-4">
      <motion.form 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.6 }}
        onSubmit={handleSubmit} 
        className="space-y-3"
      >
        <div className="relative group">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your business email"
            className="w-full px-4 py-3 rounded-lg gradient-border bg-command-black text-pearl placeholder-pearl/20 focus:outline-none focus:ring-1 focus:ring-power-gold/20 transition-all duration-300 group-hover:bg-strategic-gray/5 text-sm font-mono"
            required
            disabled={status === 'sending'}
          />
          <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-power-gold/5 to-rose-gold/5 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none" />
        </div>

        <motion.button
          type="submit"
          className="w-full px-4 py-3 bg-power-gradient text-command-black rounded-lg font-mono font-semibold tracking-wider transition-all duration-300 hover:shadow-power text-sm uppercase disabled:opacity-50 disabled:cursor-not-allowed"
          whileHover={{ scale: status === 'sending' ? 1 : 1.01 }}
          whileTap={{ scale: status === 'sending' ? 1 : 0.99 }}
          disabled={status === 'sending'}
        >
          {status === 'sending' ? 'Processing...' : 'Join Private Waitlist'}
        </motion.button>

        {status === 'success' && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center text-power-gold text-sm font-mono"
          >
            Position secured. Welcome to Crown Intel.
          </motion.p>
        )}

        {status === 'error' && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center text-rose-gold text-sm font-mono"
          >
            Strategic error. Please try again.
          </motion.p>
        )}

        <p className="text-center text-pearl/40 text-xs font-mono">
          Submission implies acceptance of our{' '}
          <Link to="/terms" className="text-power-gold hover:text-rose-gold transition-colors duration-300">
            Terms
          </Link>{' '}
          and{' '}
          <Link to="/privacy" className="text-power-gold hover:text-rose-gold transition-colors duration-300">
            Privacy Policy
          </Link>
        </p>
      </motion.form>
      
      <div className="text-center">
        <p className="text-pearl/30 text-xs font-mono">
          © 2024 Crown Intel. All rights reserved.
        </p>
      </div>
    </div>
  );
};